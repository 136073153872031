import React, { FC, useState, useRef, useEffect } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import Typewriter, { TypewriterClass } from 'typewriter-effect';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollIndicatorIcon from '../../assets/icons/scroll-indicator.inline.svg';
import HeroImg from '../../assets/images/home-hero.webp';
import TextScramble from '../../utils/animations/TextScramble';

import './Hero.scss';

gsap.registerPlugin(ScrollTrigger);

export type HeroProps = {
    headline: string;
    paragraph: string;
};

const TEXTS = ['code', 'craft', 'ideate', 'create'];

const Hero: FC<HeroProps> = props => {
    const [scramblerWord, setScramblerWord] = useState('create');
    const [windowWidth, setwindowWidth] = useState(0);
    const heroRef = useRef<HTMLElement>(null);
    const textRef = useRef<any>(null);
    const heroParagraphRef = useRef<HTMLDivElement>(null);
    const heroBackgroundRef = useRef<HTMLDivElement>(null);
    const scrollIndicatorArrowRef = useRef<HTMLDivElement>(null);
    const scrollIndicatorLabelRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.querySelector('html')?.classList.add('overflow-hidden');
    }, []);

    const startScrambleHandler = async () => {
        const scrambleDiv = textRef.current.querySelector('.scrambled-word');
        const textScramble = new TextScramble(scrambleDiv);

        let counter = 0;
        const doNext = () => {
            textScramble.setText(TEXTS[counter])?.then(() => {
                setTimeout(doNext, 800);
            });
            counter = (counter + 1) % TEXTS.length;
        };

        doNext();
    };

    const scrollIndicatorHandler = () => {
        document.querySelector('.phrase')?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    const typewritterInitHandler = (typewriter: TypewriterClass) => {
        typewriter
            .pauseFor(250)
            .typeString(
                'We <span class="scrambled-word">create</span> <br class="mobile-only"/>amazing <br class="desktop-only"/>digital products.<br/>',
            )
            .start()
            .callFunction(() => {
                startScrambleHandler();

                document.querySelector('.navbar')?.classList.add('animation-play');
                heroParagraphRef.current?.classList.add('animation-play');
                scrollIndicatorArrowRef.current?.classList.add('animation-play');
                scrollIndicatorLabelRef.current?.classList.add('animation-play');
                heroBackgroundRef.current?.classList.add('hero__background--visible');
                document
                    .querySelector('.hero__headline-cursor')
                    ?.classList.add('hero__headline-cursor--stop-animation');
                document.querySelector('html')?.classList.remove('overflow-hidden');

                setTimeout(() => {
                    scrollIndicatorArrowRef.current?.classList.add('moveUpDown');
                }, 500);
            });
    };

    useEffect(() => {
        const hero = document.querySelector('.hero');
        document.querySelector('html')?.classList.add('overflow-hidden');
        if (hero && !ScrollTrigger.isInViewport(hero, 0.75)) {
            document.querySelector('html')?.classList.remove('overflow-hidden');
        }

        const doc = document.documentElement;
        const drawerHeight = () => {
            doc.style.setProperty('--display-height', `${window.innerHeight}px`);
        };
        window.addEventListener('resize', drawerHeight);
        drawerHeight();

        const handleWindowWidthChange = () => {
            setwindowWidth(doc.clientWidth);
        };
        window.addEventListener('resize', handleWindowWidthChange);

        return () => {
            window.removeEventListener('resize', drawerHeight);
            window.removeEventListener('resize', handleWindowWidthChange);
        };
    }, []);

    useEffect(() => {
        if (textRef && textRef.current && textRef.current.querySelector('.scrambled-word')) {
            textRef.current.querySelector('.scrambled-word').textContent = scramblerWord;
        }
    }, [scramblerWord]);

    return (
        <section ref={heroRef} className="hero">
            <div className="hero__background" aria-hidden="true" ref={heroBackgroundRef}>
                <ParallaxBanner
                    layers={[
                        {
                            disabled: heroRef.current?.offsetWidth! < 1024 ? true : false,
                            image: HeroImg,
                            speed: -25,
                            scale: [0.7, 1.4, 'easeInOut'],
                            expanded: false,
                            opacity: [1, 0.3],
                            translateY: [20, -20],
                        },
                    ]}
                    className="hero__background-image"
                />
            </div>
            <div ref={textRef} className="hero__wrapper">
                <div className="hero__content">
                    <div className="hero__typewritter" aria-hidden="true">
                        <Typewriter
                            options={{
                                wrapperClassName: 'Typewriter__wrapper hero__headline',
                                cursorClassName: 'Typewriter__cursor hero__headline-cursor',
                                cursor: '&#92;',
                                delay: 50,
                            }}
                            onInit={typewriter => typewritterInitHandler(typewriter)}
                        />
                    </div>
                    <h1 className="sr-only">We create amazing digital products</h1>
                    <p className="hero__paragraph animated fadeInUp" ref={heroParagraphRef}>
                        {props.paragraph}
                    </p>
                </div>
                <div className="hero__scroll-indicator">
                    <div className="scroll-indicator" onClick={scrollIndicatorHandler}>
                        <span
                            className="scroll-indicator__label animated fadeInUp"
                            ref={scrollIndicatorLabelRef}>
                            <span
                                className="scroll-indicator__hover-text"
                                data-content="Scroll to explore"
                                aria-hidden="true"></span>
                            Scroll to explore
                        </span>
                        <div
                            className="scroll-indicator__arrow animated fadeInUp"
                            ref={scrollIndicatorArrowRef}>
                            <ScrollIndicatorIcon />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
