import React, { useEffect, useRef } from 'react';
import ArrowLeft from '../../../assets/icons/arrowLeft.inline.svg';
import gsap from 'gsap';

import './Service.scss';

type ServicePosition = 'Right' | 'Left';

type ServiceProduct = {
    name: string;
};

type ServiceProps = {
    id: number;
    name: string;
    description: string;
    products: ServiceProduct[];
    position: ServicePosition;
};

const Service = ({ id, name, description, products, position }: ServiceProps) => {
    const firstProducts = products.slice(0, Math.ceil(products.length / 2));
    const secondProducts = products.slice(Math.ceil(products.length / 2), products.length);
    const serviceHeaderRef = useRef<HTMLDivElement>(null);
    const serviceContentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: `.service--${id}`,
                start: 'top center',
                scrub: 1,
                onToggle: self => {
                    if (self.isActive) {
                        serviceHeaderRef.current?.classList.add('animation-play');

                        serviceContentRef.current?.classList.add('animation-play');
                    }
                },
            },
        });
    }, []);

    return (
        <div className={`service service--${id} service--${position === 'Right' && 'right'}`}>
            <div className={`service__wrapper`}>
                <div
                    ref={serviceHeaderRef}
                    className={`service__header ${
                        position === 'Right'
                            ? 'animated fadeInRight--smaller'
                            : 'animated fadeInLeft--smaller'
                    }`}>
                    <h3 className="service__name">{name}</h3>
                    <div className="service__header-box">
                        <p className="service__name">/</p>
                        <ArrowLeft />
                    </div>
                </div>
                <div
                    ref={serviceContentRef}
                    className={`service__content ${
                        position === 'Right'
                            ? 'animated fadeInLeft--smaller'
                            : 'animated fadeInRight--smaller'
                    }`}>
                    <p className="service__description">{description}</p>
                    <div className="service__products">
                        <ol className="service__products-column">
                            {firstProducts.map((product, index) => {
                                return (
                                    <li className="service__product" key={index + 1}>
                                        <span className="service__product-number">
                                            {index >= 10 ? `${index + 1}` : `0${index + 1}`} -
                                        </span>
                                        {' ' + product.name}
                                    </li>
                                );
                            })}
                        </ol>
                        <ol className="service__products-column">
                            {secondProducts.map((product, index) => {
                                return (
                                    <li
                                        className={`service__product service__product${
                                            products.length % 2 !== 0 ? '--with-border' : ''
                                        }`}
                                        key={index}>
                                        <span className="service__product-number">
                                            {firstProducts.length + index >= 10
                                                ? `${firstProducts.length + index + 1}`
                                                : `0${firstProducts.length + index + 1}`}{' '}
                                            -
                                        </span>
                                        {' ' + product.name}
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;

export type { ServiceProps };
