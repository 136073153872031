import React, { useContext, useEffect } from 'react';
import { AppContext, AppContextProps } from '../store/context';
import { HISTORYSLIDES, SERVICES } from '../mock/SiteData';
import ContactUsSection from '../sections/ContactUs/ContactUs';
import ImgSliderDesk from '../sections/ImgSliderDesk/ImgSliderDesk';
import Phrase from '../sections/Phrase/Phrase';
import HistorySlider from '../sections/HistorySlider/HistorySlider';
import Services from '../components/Services/Services';
import Hero from '../sections/Hero/Hero';

import 'normalize.css';
import ImgSliderMobile from '../sections/ImgSliderMobile/ImgSliderMobile';
import MouseContextProvider from '../store/cursor';
import SEO from '../components/SearchEngineOptimizations/SearchEngineOptimizations';

const Home = () => {
    const { setCurrentPage } = useContext(AppContext) as AppContextProps;

    useEffect(() => {
        setCurrentPage('Home');
    }, []);

    return (
        <main className="page page--home">
            <MouseContextProvider>
                <Hero
                    headline="We create amazing digital products.<br/>\"
                    paragraph="If you are looking for the perfect development partner, you are in the right place."
                />
                <Phrase text="/ Some call us <w>freaks,<w> people obsessed with computers and technology from a young age. But we call that <w>passion,<w> and that passion is the driver that allows us to loop through a piece of code unlimited times <w>‘til<w> <w>we<w> <w>find<w> <w>perfection.<w>" />
                <ImgSliderDesk />
                <ImgSliderMobile />
                <Services
                    headline={SERVICES.headline}
                    subheadline={SERVICES.subheadline}
                    services={SERVICES.services}
                />
                <HistorySlider slides={HISTORYSLIDES} />
                <ContactUsSection className="home__contact-us" />
            </MouseContextProvider>
        </main>
    );
};

export default Home;

export const Head = () => (
    <SEO
        title="Loop3 Studio - Home"
        description="If you are looking for the perfect development partner, you are in the right place."
    />
);
