import { navigate } from 'gatsby';
import gsap from 'gsap';
import React, { useEffect, useRef, useState, useContext } from 'react';
import horizontalLoop from '../../hooks/useHorizontalLoop';
import { MouseContext } from '../../store/cursor';

import './SlideTitles.scss';
interface SlideTitlesProps {
    titles: { title: string; link: string }[];
    currentIndex: number;
    className?: string;
    changeSlideFoward: () => void;
    changeSlideBackward: () => void;
    previousArrowCursor: string;
    setPreviousArrowCursor: (type: string) => void;
}
const SlideTitles = ({
    titles,
    currentIndex,
    changeSlideFoward,
    changeSlideBackward,
    previousArrowCursor,
    setPreviousArrowCursor,
    className,
}: SlideTitlesProps) => {
    const { cursorType, cursorChangeHandler } = useContext(MouseContext);
    const titleAnimationTl = useRef<GSAPTimeline | null>(null);
    const [previousTitleIndex, setPreviousTitleIndex] = useState(0);

    useEffect(() => {
        const items = gsap.utils.toArray('.slide-titles__item');

        titleAnimationTl.current = horizontalLoop(items, { paused: true });
    }, []);

    useEffect(() => {
        if (!titleAnimationTl?.current || (previousTitleIndex === 0 && currentIndex === 0)) return;

        const nextIndex = (previousTitleIndex + 1) % titles.length;
        const prevIndex = previousTitleIndex === 0 ? titles.length - 1 : previousTitleIndex - 1;

        if (currentIndex === nextIndex) {
            titleAnimationTl.current.next({ duration: 1, ease: 'power1.inOut' });
        }

        if (currentIndex === prevIndex) {
            titleAnimationTl.current.previous({ duration: 1, ease: 'power1.inOut' });
        }

        setPreviousTitleIndex(currentIndex);
    }, [currentIndex]);

    return (
        <div className={`slide-titles${className ? className : ''}`}>
            <div
                className="slide-titles__wrapper"
                onClick={() => {
                    if (previousArrowCursor === 'arrow') {
                        changeSlideFoward();
                    } else if (
                        previousArrowCursor === 'arrow--inverted' &&
                        cursorType !== 'view-more'
                    ) {
                        changeSlideBackward();
                    }
                }}
                onMouseLeave={() => cursorChangeHandler('default')}
                onMouseEnter={() => {
                    if (previousArrowCursor) {
                        cursorChangeHandler(previousArrowCursor);
                    } else return;
                }}>
                {titles.map((title, index) => {
                    const isNext =
                        currentIndex === titles.length - 1
                            ? index === 0
                            : index === currentIndex + 1;
                    return (
                        <div key={`slide__title--${index}`} className={`slide-titles__item `}>
                            <h3
                                className={`slide-titles__title ${
                                    index === currentIndex
                                        ? 'slide-titles__title--active'
                                        : isNext
                                        ? 'slide-titles__title--next'
                                        : ''
                                }`}
                                onClick={() => {
                                    if (!isNext && index !== currentIndex) return;
                                    if (isNext) {
                                        return changeSlideFoward();
                                    }
                                    return navigate(title.link);
                                }}
                                onMouseEnter={() => {
                                    if (!isNext && index !== currentIndex) return;
                                    if (isNext) {
                                        cursorChangeHandler('arrow');
                                        return;
                                    }

                                    cursorChangeHandler('view-more');
                                }}
                                onMouseLeave={() => {
                                    if (index !== currentIndex) return;
                                    cursorChangeHandler(previousArrowCursor);
                                }}>
                                {title.title}
                            </h3>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SlideTitles;
