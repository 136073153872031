import React, { createContext, useState } from 'react';

export const MouseContext = createContext({
    cursorType: '',
    cursorChangeHandler: (type: string) => undefined,
});

interface MouseContextProps {
    children: JSX.Element[];
}
const MouseContextProvider = (props: MouseContextProps) => {
    const [cursorType, setCursorType] = useState('');

    const cursorChangeHandler = (type: string) => setCursorType(type);

    return (
        <MouseContext.Provider
            value={{
                cursorType,
                cursorChangeHandler,
            }}>
            {props.children}
        </MouseContext.Provider>
    );
};
export default MouseContextProvider;
