import React, { Component } from 'react';

type TextScrambleProps = {
    el: HTMLDivElement | null;
};

type TextScrambleState = {
    el: HTMLDivElement | null;
    chars: string;
    queue: { from: string; to: string; start: number; end: number; char?: string }[];
    resolve?: (value?: unknown) => void;
    frameRequest: number;
    frame: number;
};

export default class TextScramble extends Component<TextScrambleProps, TextScrambleState> {
    state: TextScrambleState = {
        el: null,
        chars: '!<>-_\\/[]{}—=+*^?#_',
        queue: [],
        frameRequest: 0,
        frame: 0,
    };
    constructor(el: HTMLDivElement) {
        super({ el });
        this.state.el = el;
        this.update = this.update.bind(this);
    }
    setText(newText: string) {
        if (!this.state.el) {
            return;
        }

        const oldText = this.state.el.innerText;
        const length = Math.max(oldText.length, newText.length);
        const promise = new Promise(resolve => (this.state.resolve = resolve));
        this.state.queue = [];
        for (let i = 0; i < length; i++) {
            const from = oldText[i] || '';
            const to = newText[i] || '';
            const start = Math.floor(Math.random() * 40);
            const end = start + Math.floor(Math.random() * 40);
            this.state.queue.push({ from, to, start, end });
        }
        cancelAnimationFrame(this.state.frameRequest);
        this.state.frame = 0;
        this.update();
        return promise;
    }
    update() {
        if (!this.state.el) {
            return;
        }

        let output = '';
        let complete = 0;
        for (let i = 0, n = this.state.queue.length; i < n; i++) {
            const { from, to, start, end, char } = this.state.queue[i];
            if (this.state.frame >= end) {
                complete++;
                output += to;
            } else if (this.state.frame >= start) {
                if (!char || Math.random() < 0.28) {
                    this.state.queue[i].char = this.randomChar();
                }
                output += `<span class="scramble__char">${this.state.queue[i].char}</span>`;
            } else {
                output += from;
            }
        }

        this.state.el.innerHTML = output;
        if (complete === this.state.queue.length && this.state.resolve) {
            this.state.resolve();
        } else {
            this.state.frameRequest = requestAnimationFrame(this.update);
            this.state.frame++;
        }
    }
    randomChar() {
        return this.state.chars[Math.floor(Math.random() * this.state.chars.length)];
    }
}
