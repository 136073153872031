import React, { useEffect, useState, useRef } from 'react';
import { Media } from '../../components/Media/Media';
import Rarrow from '../../assets/icons/shape_arrow.svg';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import AnimateText from '../../components/AnimateText/AnimateText';

import './Phrase.scss';

gsap.registerPlugin(ScrollTrigger);

type PhraseProps = {
    text: string;
};

const Phrase = ({ text }: PhraseProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const phraseRef = useRef<HTMLElement>(null);

    useEffect(() => {
        gsap.to(phraseRef.current, {
            scrollTrigger: {
                trigger: phraseRef.current,
                start: '30% bottom',
                onToggle: self => {
                    if (self.isActive) {
                        document.querySelector('.phrase__arrow')?.classList.add('animation-play');
                        setIsLoading(false);
                    }
                },
            },
        });
    }, []);

    return (
        <section ref={phraseRef} className="phrase">
            <div className="phrase__wrapper">
                <Media
                    source={Rarrow}
                    className="phrase__arrow animated fadeInLeft"
                    alt="arrow"
                    noPicture={true}
                    hidden={true}
                />
                {!isLoading ? (
                    <div className="phrase__content">
                        <h2 className="phrase__text">
                            <AnimateText text={text} trigger={'.phrase'} />
                        </h2>
                    </div>
                ) : null}
            </div>
        </section>
    );
};

export default Phrase;
export type { PhraseProps };
