import React, { useContext } from 'react';
import { MouseContext } from '../../store/cursor';
import './Slide.scss';

interface SlideProps {
    images: string[];
    currentIndex: number;
    changeSlideFoward: () => void;
    changeSlideBackWard: () => void;
    previousArrowCursor: string;
    setPreviousArrowCursor: (type: string) => void;
}

const Slide = ({
    images,
    currentIndex,
    changeSlideFoward,
    changeSlideBackWard,
    previousArrowCursor,
    setPreviousArrowCursor,
}: SlideProps) => {
    const { cursorChangeHandler } = useContext(MouseContext);

    return (
        <div className="slide">
            {images.map((img, index) => (
                <img
                    key={index}
                    src={img}
                    alt={`image-${index + 1}`}
                    className={`slide__img slide__img--${index} ${
                        currentIndex === index ? 'slide__img--visible' : 'slide__img--hidden'
                    }`}
                    loading="lazy"
                />
            ))}
            <div
                className="slide__click slide__click--left"
                onClick={changeSlideBackWard}
                onMouseEnter={() => {
                    cursorChangeHandler('arrow--inverted');
                    setPreviousArrowCursor('arrow--inverted');
                }}
            />
            <div
                className="slide__click slide__click--right"
                onClick={changeSlideFoward}
                onMouseEnter={() => {
                    setPreviousArrowCursor('arrow');
                    cursorChangeHandler('arrow');
                }}
            />
        </div>
    );
};

export default Slide;
