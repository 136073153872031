import React, { FC, useEffect, useRef, useState } from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import CircleShape from '../../assets/icons/shape_more.svg';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { yupResolver } from '@hookform/resolvers/yup';

import './ContactUs.scss';
import { useForm, Controller } from 'react-hook-form';
import { defaultFormValues, contactUsSchema } from './formSchema';
import TextAreaInput from '../../components/Input/TextAreaInput';
import { timeStamp } from 'console';

type ContactUsSectionProps = {
    className?: string;
};

export type FormProps = {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    phone: string;
    detail: string;
};

gsap.registerPlugin(ScrollTrigger);

const formApiUrl =
    'https://api.hsforms.com/submissions/v3/integration/submit/23222022/f1f4ee1c-153b-47dc-8c9a-448b3263731c';

const ContactUs: FC<ContactUsSectionProps> = props => {
    const {
        handleSubmit,
        reset,
        control,
        formState: { isSubmitting, errors, isSubmitSuccessful, isSubmitted },
    } = useForm<FormProps>({
        defaultValues: defaultFormValues,
        resolver: yupResolver(contactUsSchema),
    });

    const formStateRef = useRef<HTMLParagraphElement>(null);
    const shapeCircleRef = useRef<HTMLDivElement>(null);
    const shapeCircleIconRef = useRef<HTMLImageElement>(null);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const submitHandler = (data: FormProps) => {
        const { firstName, lastName, company, email, phone, detail } = data;
        setIsSubmittingForm(true);

        fetch(formApiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },

            body: JSON.stringify({
                fields: [
                    {
                        name: 'Firstname',
                        value: firstName,
                    },
                    {
                        name: 'Lastname',
                        value: lastName,
                    },
                    {
                        name: 'Company',
                        value: company,
                    },
                    {
                        name: 'Email',
                        value: email,
                    },
                    {
                        name: 'Phone',
                        value: phone,
                    },
                    {
                        name: 'Detail',
                        value: detail,
                    },
                ],
                context: {
                    pageName: 'Loop3Website',
                },
            }),
        })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then(() => {
                setIsSubmittingForm(false);
                formStateRef.current?.classList.add('animation-play');
                reset(defaultFormValues, { keepIsSubmitted: true });
            });
    };

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.contact-us__wrapper',
                start: 'top center',
                scrub: 1,
                onEnter: () => {
                    shapeCircleRef.current?.classList.add('animation-play');
                    const timeout = setTimeout(() => {
                        shapeCircleRef.current?.classList.add('rotateRight');
                        shapeCircleIconRef.current?.classList.add('pulse');
                        clearTimeout(timeout);
                    }, 5000);
                },
            },
        });
    }, []);

    return (
        <section
            className={`contact-us${props.className ? ` ${props.className}` : ''}`}
            id="contact-us">
            <div className="contact-us__wrapper">
                <div className="contact-us__title-section">
                    <h2 className="heading-2 contact-us__title">
                        Contact us
                        <br />\
                    </h2>
                    <p className="contact-us__title-description">
                        YOU HAVE AN IDEA? NEW PRODUCT OR A WEBSITE? LET US TACKLE EVERY STEP OF THE
                        PROCESS WITH YOU.
                    </p>
                </div>
                <form
                    className="contact-us__form"
                    onSubmit={handleSubmit(submitHandler)}
                    noValidate>
                    <div className="contact-us__form-input-wrapper--name">
                        <Controller
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Input
                                    type="text"
                                    label="First name"
                                    name="firstName"
                                    className="contact-us__form-input form-input__name--first"
                                    hasError={errors.firstName}
                                    required={true}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            )}
                            name="firstName"
                        />
                        <Controller
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Input
                                    type="text"
                                    label="Last name"
                                    name="lastName"
                                    className="contact-us__form-input form-input__name--last"
                                    hasError={errors.lastName}
                                    required={true}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            )}
                            name="lastName"
                        />
                    </div>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                type="text"
                                label="Company"
                                name="company"
                                className="contact-us__form-input form-input__company"
                                hasError={errors.company}
                                required={true}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                        name="company"
                    />
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                type="text"
                                label="Email"
                                name="email"
                                className="contact-us__form-input form-input__email"
                                hasError={errors.company}
                                required={true}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                        name="email"
                    />
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                type="text"
                                label="Phone"
                                name="phone"
                                className="contact-us__form-input form-input__number"
                                hasError={errors.phone}
                                required={true}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                        name="phone"
                    />
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <TextAreaInput
                                type="textarea"
                                label="Tell us about needs"
                                name="detail"
                                className="contact-us__form-input form-input__detail"
                                hasError={errors.detail}
                                required={true}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                        name="detail"
                    />
                    <input
                        type="password"
                        name="password"
                        className="contact-us__form-input--extra"
                        tabIndex={-1}
                        autoComplete="false"
                    />
                    <div className="contact-us__form-button-wrapper">
                        <Button
                            className="contact-us__form-button"
                            tag="button"
                            isLoading={isSubmittingForm}>
                            Send message
                        </Button>
                        <p
                            ref={formStateRef}
                            className={`contact-us__form-state animated fadeInUp ${
                                isSubmitted
                                    ? 'contact-us__form-state--success'
                                    : 'contact-us__form-state--error'
                            }`}>
                            {isSubmitted ? (
                                <>
                                    Thanks for reaching out,
                                    <br /> we will get back to you shortly
                                </>
                            ) : (
                                <>An error has ocurred, please try again</>
                            )}
                        </p>
                    </div>
                </form>
                <div className="contact-us__shape-circle animated fadeInRight" ref={shapeCircleRef}>
                    <img
                        ref={shapeCircleIconRef}
                        src={CircleShape}
                        alt="circle-shape"
                        className="contact-us__shape-circle-icon"
                    />
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
