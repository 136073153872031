import React, { useState } from 'react';
import Slider, { SlideProps } from '../../components/Slider/Slider';
import { CaseStudyData } from '../../mock/SiteData';

import 'swiper/css';
import './ImgSliderMobile.scss';

const ImgSliderMobile: React.FC<{}> = () => {
    const [slideNumber, setSlideNumber] = useState<number>(0);

    const [slides, setSlides] = useState<SlideProps[]>(
        CaseStudyData.map(caseStudy => ({
            img: caseStudy.image,
            imgMobile: caseStudy.image,
            link: caseStudy.link,
            txt: caseStudy.description,
            title: caseStudy.name,
            id: `sm-${caseStudy.id}`,
        })),
    );

    const onChangeSlide = (index: number) => {
        setSlideNumber(index);
    };

    return (
        <section className="slider image-slider-mobile">
            <Slider slides={slides} isImageSlider={true} onSlideChange={onChangeSlide} />
            <div className="slider__p-wrapper">
                <p className="slider__p">{slides[slideNumber].txt}</p>
            </div>
        </section>
    );
};

export default ImgSliderMobile;
