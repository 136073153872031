import React, { FC, InputHTMLAttributes, useState } from 'react';
import { FieldError } from 'react-hook-form/dist/types';
import { UseFormRegister } from 'react-hook-form';

import './Input.scss';
import { FormProps } from '../../sections/ContactUs/ContactUs';

export interface TextAreaInputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    hasError: boolean | FieldError | undefined;
    label: string;
    inputValidation?: {};
}

const TextAreaInput: FC<TextAreaInputProps> = ({
    type,
    placeholder,
    name,
    className,
    hasError,
    required,
    label,
    inputValidation,
    onChange,
    ...rest
}) => {
    const [inputChanged, setInputChanged] = useState(false);
    return (
        <div className="input__wrapper">
            <textarea
                id={`${name}-input`}
                placeholder={placeholder}
                className={`input__input input__input--text-area ${className} ${
                    inputChanged ? '' : 'input__neutral-outline'
                } ${hasError ? 'input__invalid' : 'input__valid'}`}
                onChange={onChange}
                {...rest}
            />
            <label htmlFor={`${name}-input`} className="input__label">
                {label}
            </label>
            <br />
        </div>
    );
};

export default TextAreaInput;
