import gsap from 'gsap';
import React, { useState, useEffect, useRef } from 'react';
import './Counter.scss';
interface CounterProps {
    currentIndex: number;
    className?: string;
    counterHeight: number;
    maxCount: number;
}

const Counter = ({ currentIndex, maxCount, className, counterHeight }: CounterProps) => {
    const twoDigits = maxCount >= 10;
    const countNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    const itemPercentage = (1 / countNumbers.length) * 100;
    const [secondDigit, setSecondDigit] = useState<number>(1);
    const [firstDigit, setFirstDigit] = useState<number>(0);
    const [isStarting, setIsStarting] = useState<boolean>(true);
    const firstDigitTl = useRef<GSAPTimeline | null>(null);
    const secondDigitTl = useRef<GSAPTimeline | null>(null);

    useEffect(() => {
        secondDigitTl.current = gsap.timeline({});
        secondDigitTl.current.to('.counter__list--2', {
            y: `-${itemPercentage}%`,
        });
        if (twoDigits) {
            firstDigitTl.current = gsap.timeline({});
        }
        setIsStarting(false);
    }, []);

    useEffect(() => {
        if (isStarting) return;

        if (currentIndex + 1 < 10) {
            setSecondDigit(currentIndex + 1);

            if (firstDigit >= 1) {
                setFirstDigit(0);
            }
        }

        if (currentIndex + 1 >= 10) {
            const index = (currentIndex + 1).toString().split('');
            const firstNumber = Number(index[0]);
            const secondNumber = Number(index[1]);
            setSecondDigit(secondNumber);

            if (firstNumber === firstDigit) return;
            else {
                setFirstDigit(firstNumber);
            }
        }
    }, [currentIndex]);

    useEffect(() => {
        if (!secondDigitTl?.current || isStarting) return;

        const moveTo = itemPercentage * secondDigit;

        secondDigitTl.current.to('.counter__list--2', {
            y: `-${moveTo}%`,
        });
    }, [secondDigit]);

    useEffect(() => {
        if (!firstDigitTl?.current || isStarting) return;

        const moveTo = itemPercentage * firstDigit;

        firstDigitTl.current.to('.counter__list--1', {
            y: `-${moveTo}%`,
        });
    }, [firstDigit]);

    return (
        <div className={`counter${className ? ` ${className}` : ''}`}>
            <div
                className={`counter__wrapper${
                    typeof counterHeight === 'string' ? ` ${counterHeight}` : ''
                }`}
                style={typeof counterHeight === 'number' ? { maxHeight: counterHeight } : {}}>
                {twoDigits ? (
                    <ul className="counter__list counter__list--1">
                        {countNumbers.map(item => (
                            <li
                                className={`counter__list-item counter__list-item--${item}`}
                                style={{ height: counterHeight }}>
                                {item}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <ul className="counter__list counter__list--1">
                        <li
                            className="counter__list-item counter__list-item--0"
                            style={{ height: counterHeight }}>
                            0
                        </li>
                    </ul>
                )}
                <ul className="counter__list counter__list--2">
                    {countNumbers.map((item, index) => (
                        <li
                            key={index}
                            className={`counter__list-item counter__list-item--${item}`}
                            style={{ height: counterHeight }}>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Counter;
