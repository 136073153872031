import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Service, { ServiceProps } from './Service/Service';
import useTriggerLightTheme from '../../hooks/useTriggerLightTheme';

import './Services.scss';

gsap.registerPlugin(ScrollTrigger);

type ServicesProps = {
    headline: string;
    subheadline: string;
    services: ServiceProps[];
};

const Services = ({ headline, subheadline, services }: ServicesProps) => {
    const servicesRef = useRef<HTMLDivElement | null>(null);
    const servicesHeadlineRef = useRef<HTMLDivElement | null>(null);
    const servicesSubHeadlineRef = useRef<HTMLDivElement | null>(null);
    const [tlState, setTlState] = useState<GSAPTimeline | null>(null);
    const [offsetStart, setOffsetStart] = useState<number>(0);
    const [offsetEnd, setOffsetEnd] = useState<number>(0);
    const [width, setWidth] = useState(0);

    const tl = gsap.timeline({ paused: true });

    const clearGsapAnimation = async () => {
        gsap?.killTweensOf('.services__title');
    };

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setWidth]);

    useEffect(() => {
        tl.to('.history-slider__cover--background', { opacity: 1, duration: 0.5 })
            .to('.history-slider__cover--division', { opacity: 0, duration: 0 }, 'end')
            .to(
                ['.layout__wrapper', '.phrase', '.history-slider__cover--background'],
                { background: '#ffffff', duration: 0.5 },
                'end',
            )
            .to(
                [
                    '.services__wrapper',
                    '.slider__title',
                    '.slider__slide-number',
                    '.slider__counter-wrapper',
                ],
                { color: '#162a33', duration: 0 },
                'end',
            )
            .to(['.services__headline'], { color: '#0B0C0D', duration: 0 }, 'end');

        setOffsetStart((window.innerHeight * 1) / 10);
        setOffsetEnd((window.innerHeight * 0.5) / 10);

        setTlState(tl);
    }, []);

    useEffect(() => {
        if (width === 0) {
            return;
        }

        clearGsapAnimation();

        let triggerStartTitle: string = '+=10% 75%';
        let triggerStartDescription: string = '+=15% 75%';

        if (width >= 1024) {
            triggerStartTitle = '+=15% center';
            triggerStartDescription = '+=20% center';
        }

        gsap.timeline({
            scrollTrigger: {
                trigger: '.services',
                start: triggerStartTitle,
                scrub: 1,
                onToggle: self => {
                    if (self.isActive) {
                        servicesHeadlineRef.current?.classList.add('animation-play');
                    }
                },
            },
        });

        gsap.timeline({
            scrollTrigger: {
                trigger: '.services',
                start: triggerStartDescription,
                scrub: 1,
                onEnter: () => {
                    servicesSubHeadlineRef.current?.classList.add('animation-play');
                },
            },
        });
    }, [width]);

    useTriggerLightTheme(servicesRef.current, offsetStart, offsetEnd, tlState, 0.5);

    return (
        <section id="services" className="services">
            <div ref={servicesRef} className="services__wrapper">
                <div ref={servicesRef}>
                    <div className="services__header">
                        <h2
                            ref={servicesHeadlineRef}
                            dangerouslySetInnerHTML={{ __html: headline }}
                            className="services__headline animated fadeInUp"
                        />
                        <p
                            ref={servicesSubHeadlineRef}
                            className="services__subheadline animated fadeInUp"
                            dangerouslySetInnerHTML={{ __html: subheadline }}
                        />
                    </div>
                    <div className="services__list">
                        {services.map(service => {
                            return (
                                <Service
                                    key={service.id}
                                    id={service.id}
                                    name={service.name}
                                    description={service.description}
                                    products={service.products}
                                    position={service.position}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;

export type { Service };
