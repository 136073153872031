import React, { useContext, useState, useEffect } from 'react';
import useMousePosition from '../../hooks/useMousePosition';
import { MouseContext } from '../../store/cursor';
import ArrowImg from '../../assets/cursors/right-arrow.svg';
import ViewMoreImg from '../../assets/cursors/view-more.svg';
import './Cursor.scss';

const Cursor = () => {
    const [className, setClassName] = useState<string>('default');
    const [image, setImage] = useState<null | string>(null);
    const { cursorType } = useContext(MouseContext);
    const { x, y } = useMousePosition();

    useEffect(() => {
        switch (cursorType) {
            case 'arrow':
                setClassName('cursor arrow-cursor');
                setImage(ArrowImg);
                break;
            case 'arrow--inverted':
                setClassName('cursor arrow-cursor arrow-cursor--inverted');
                setImage(ArrowImg);
                break;
            case 'view-more':
                setClassName('cursor view-more');
                setImage(ViewMoreImg);
                break;
            case 'default':
                setClassName('cursor');
                break;
            default:
                break;
        }
    }, [cursorType]);

    return (
        <div className={className} style={{ left: `${x}px`, top: `${y}px` }}>
            {image && <img src={image} alt="->" className={'cursor__custom-image'} />}
        </div>
    );
};
export default Cursor;
