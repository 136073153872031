import React, { useContext, useEffect, useRef, useState } from 'react';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { AppContext } from '../../store/context';

import { Media } from '../../components/Media/Media';
import Button from '../../components/Button/Button';
import Counter from '../../components/counter/Counter';

import Key from '../../assets/images/{+}.svg';
import RArr from '../../assets/icons/shape_arrow.svg';

import './HistorySlider.scss';
import { TABLET_SMALL } from '../../utils/variables';

type HistorySlideCta = {
    label: string;
    url: string;
};

type HistorySlideBrand = {
    name: string;
    img: string;
};

type HistorySlide = {
    id: number;
    headline: string;
    body?: string;
    brands?: HistorySlideBrand[];
    cta?: HistorySlideCta;
};

type HistorySliderProps = {
    slides: HistorySlide[];
};

gsap.registerPlugin(ScrollTrigger);

const HistorySlide = ({ id, headline, body, brands, cta }: HistorySlide) => {
    return (
        <div key={id} className="history-slider__history-slide history-slide">
            <div className="history-slide__wrapper" key={id}>
                <span className="history-slide__counter">0{id}</span>
                {id === 0 ? <h2 className="history-slide__headline">{headline}</h2> : null}
                {id > 0 ? <h3 className="history-slide__headline">{headline}</h3> : null}
                <p className="history-slide__body">{body}</p>
                {brands ? (
                    <div className="history-slide__history-brands history-brands">
                        <div className="history-brands__wrapper">
                            {brands.map((brand, index) => (
                                <Media
                                    key={index}
                                    source={brand.img}
                                    className="history-brands__image"
                                    alt={brand.name}
                                    noPicture={true}
                                />
                            ))}
                        </div>
                    </div>
                ) : null}
                {cta ? (
                    <Button className="history-slide__button" tag="link" href={cta.url}>
                        {cta.label}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

const HistorySlider = ({ slides }: HistorySliderProps) => {
    const [width, setWidth] = useState(0);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const { lightTheme } = useContext(AppContext);

    const stickyChildDivRef = useRef<HTMLDivElement>(null);

    const clearGsapAnimation = async () => {
        gsap?.killTweensOf('.history-slider');
        gsap?.killTweensOf('.history-slider__sticky-wrapper');

        if (stickyChildDivRef?.current?.style?.transform) {
            stickyChildDivRef.current.style.removeProperty('transform');
        }
    };

    useEffect(() => {
        for (let i = 0; i < slides.length; i++) {
            const observer = new IntersectionObserver(
                () => {
                    setActiveSlideIndex(i);
                },
                { threshold: 0.9 },
            );

            const slide = stickyChildDivRef.current?.children[0].children[i];
            if (slide) {
                observer.observe(slide);
            }
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setWidth]);

    useEffect(() => {
        clearGsapAnimation();

        const fromTo = { start: '10%', end: '-80%' };

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.history-slider',
                start: 'top',
                end: 'bottom',
                scrub: 1,
            },
        });

        if (width <= 600) {
            fromTo.end = '-103%';
            fromTo.start = '5%';
        }

        if (width <= 450) {
            fromTo.start = '-11%';
        }

        if (width <= 350) {
            fromTo.start = '-12%';
        }
        // tablet
        if (width >= 1024) {
            fromTo.end = '-76%';
        }

        // desktop-large
        if (width >= 1800) {
            fromTo.end = '-70%';
        }

        tl.fromTo(
            '.history-slider__sticky-wrapper',
            { x: fromTo.start, duration: 10 },
            { x: fromTo.end, duration: 10 },
        );
        // }

        return () => {
            clearGsapAnimation();
        };
    }, [width]);

    return (
        <section className="history-slider">
            <div className="history-slider__cover--divison" aria-hidden="true"></div>
            <div className="history-slider__cover--background" aria-hidden="true"></div>
            <div className="history-slider__wrapper">
                <div className="history-slider__sticky">
                    <div className="history-slider__sticky-wrapper" ref={stickyChildDivRef}>
                        <div className="history-slider__slides">
                            {slides.map(slide => (
                                <HistorySlide key={slide.id} {...slide} />
                            ))}
                        </div>
                    </div>
                    <div className="history-slider__arrow-counter">
                        <img
                            src={RArr}
                            alt="right-arrow"
                            className="history-slider__arrow-counter-arrow"
                        />
                        <div style={{ display: 'flex' }}>
                            <Counter
                                counterHeight={70}
                                currentIndex={activeSlideIndex}
                                maxCount={slides.length}
                                className={`history-slider__arrow-counter-counter${
                                    lightTheme ? '--light-theme' : ''
                                }`}
                            />
                            <span className="history-slider__arrow-counter--grey">
                                &nbsp;
                                {slides.length >= 10 && `/${slides.length}`}
                                {slides.length < 10 && `/0${slides.length}`}
                            </span>
                        </div>
                    </div>
                    <Media
                        className="history-slider__key"
                        source={Key}
                        alt="{+}"
                        noPicture={true}
                    />
                </div>
            </div>
        </section>
    );
};

export default HistorySlider;

export type { HistorySlide };
